var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap align-center"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-3 mb-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"issue_table_search","value":_vm.searchText},on:{"input":_vm.updateSearch,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.searchText)?_c('v-icon',{staticClass:"pointer",attrs:{"size":"1.4rem"},on:{"click":_vm.clearSearch}},[_vm._v("mdi-close")]):_vm._e(),_c('v-icon',{staticClass:"pr-2",class:{ pointer: _vm.searchText },attrs:{"color":_vm.searchText ? 'primary' : ''},on:{"click":_vm.handleSearch}},[_vm._v("mdi-magnify")])]},proxy:true}])})],1),_c('div',{staticClass:"status-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"filter-icon","size":"1.7rem"}},'v-icon',attrs,false),on),[_vm._v("mdi-filter-variant")])]}}])},[_c('span',[_vm._v("Filter issue status")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.statusFilterMenuOpen,"menuActivator":"#filter-icon","menuItems":_vm.issueStatusMenuItems,"selectedMenuItems":_vm.selectedStatusFilters,"showAll":_vm.showAll,"table":"issue_table_status","offset":"x","filterType":"issue status"},on:{"changeFilters":_vm.changeStatusFilters,"clearSelectedItems":_vm.clearItems,"setSearchText":_vm.setIssueFilterSearchText,"clearFilterSearch":_vm.clearStatusFilterSearch}})],1)]),_c('div',{staticClass:"mb-3 column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"issue_table_column"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)]),_c('div',{staticClass:"data-table",attrs:{"data-cy":"issues-table-wrapper"}},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center align-center data-table__spinner"},[_c('v-progress-circular',{attrs:{"size":45,"color":"primary","indeterminate":""}})],1):_vm._e(),_c('v-data-table',{staticClass:"no-background clickable-table issues-table",attrs:{"data-cy":"issues-table","item-key":"id","show-select":false,"headers":_vm.mutableTableHeaders,"items":_vm.tableData,"page":_vm.displayPage,"footer-props":_vm.footerOptions,"items-per-page":_vm.cItemsPerPage,"sort-by":_vm.cSortBy,"options":_vm.paginationOptions,"server-items-length":_vm.totalItems},on:{"update:page":[function($event){_vm.displayPage=$event},function($event){return _vm.handlePage($event)}],"update:options":function($event){_vm.paginationOptions=$event},"update:items-per-page":function($event){return _vm.handleItemsPerPage($event)},"update:sort-desc":function($event){return _vm.handleSort($event)}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,staticClass:"no-pointer",on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unselectItem(item)}}},[(_vm.checkColumn('farm_name'))?_c('td',[_vm._v(" "+_vm._s(item.farm_name)+" ")]):_vm._e(),(_vm.checkColumn('turbine_name'))?_c('td',[_vm._v(" "+_vm._s(item.turbine_name)+" ")]):_vm._e(),(_vm.checkColumn('turbine_issue_def_name'))?_c('td',{staticClass:"issue-column"},[_c('span',{staticClass:"d-flex"},[_c('div',{staticClass:"issue-link",attrs:{"data-ga":("issue_table_link_issue_detail_page_" + (item.turbine_issue_def_name))},on:{"click":function($event){return _vm.handleClickIssue(item)}}},[_vm._v(" "+_vm._s(item.turbine_issue_def_name)+" ")]),_c('PopUp',{staticClass:"info-icon",attrs:{"content":[item.description]},scopedSlots:_vm._u([{key:"hover",fn:function(){return [_c('v-icon',{staticStyle:{"transform":"scale(0.8)"}},[_vm._v(_vm._s(_vm.informationIcon))])]},proxy:true},{key:"content",fn:function(){return [_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(_vm._s(item.description))])]},proxy:true}],null,true)})],1)]):_vm._e(),(_vm.checkColumn('turbine_issue_status'))?_c('td',[_c('div',{staticClass:"status",style:({
                  'background-color': _vm.generateStatusColor(
                    item.turbine_issue_def_status_id
                  ),
                })},[_vm._v(" "+_vm._s(item.turbine_issue_status)+" ")])]):_vm._e(),(_vm.checkColumn('start_ts'))?_c('td',[_vm._v(" "+_vm._s(item.start_ts)+" ")]):_vm._e(),(_vm.checkColumn('loss_upper_usd'))?_c('td',[(
                  item.loss_upper_usd !== null && item.aep_loss_pct !== null
                )?_c('div',{staticClass:"site-name"},[_vm._v(" $"+_vm._s(_vm.roundToString(item.loss_upper_usd, 0))+" ")]):_vm._e()]):_vm._e(),(_vm.checkColumn('aep_loss_pct'))?_c('td',[(item.aep_loss_pct !== null)?_c('div',{staticClass:"site-name"},[_vm._v(" "+_vm._s(_vm.roundToString(item.aep_loss_pct, 1))+"% ")]):_vm._e()]):_vm._e(),(_vm.checkColumn('current_status_created_ts'))?_c('td',[_vm._v(" "+_vm._s(item.current_status_created_ts)+" ")]):_vm._e()])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }