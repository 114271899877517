<template>
  <div>
    <div>
      <section class="mb-4">
        <Header
          :title="
            summaryData.turbineName ? summaryData.turbineName : 'Turbine '
          "
          :subscription="subscription"
          :subscriptionData="turbineData ? turbineData.subscription : ''"
        >
          <div class="d-flex align-center">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  data-ga="icon_turbine_dashboard_force_refresh"
                  @click="forceRefresh()"
                  class="mr-2 icon-hover"
                  >mdi-refresh</v-icon
                >
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </div>
        </Header>
      </section>

      <section>
        <TurbineSummaryWidget
          :summary="summaryData"
          :loading="loading || !summaryDataValid"
          :isInternalAdmin="turbineData.user_has_internal_access"
          @goToIssuesTable="goToIssuesTab"
          @goToChecksTab="handleTabClick('checks')"
        />
      </section>

      <section class="mt-7 mb-8">
        <div class="d-flex">
          <v-tabs v-model="tab" class="mb-4">
            <v-tab
              v-for="(tab, index) in dashboardTabs"
              @click="handleTabClick(tab)"
              :key="index"
              class="no-background"
              data-ga="turbine_dashboard_tab"
            >
              {{ tab.label }}
            </v-tab>
          </v-tabs>
          <div class="switch-wrapper">
            <SwitchButton
              label="Show map"
              color="primary"
              @changeVal="changeShowMap"
            />
          </div>
        </div>
        <v-row>
          <v-col :xl="showMap ? 9 : 12">
            <v-tabs-items
              v-model="tab"
              class="no-background"
              v-if="dashboardTabs[tab]"
            >
              <v-tab-item
                v-for="tabItem in dashboardTabs"
                :key="tabItem.value"
                :transition="false"
              >
                <template v-if="tabItem.value === 'checks'">
                  <KeepAlive>
                    <ChecksTable
                      v-if="dashboardTabs[tab].value === 'checks'"
                      :data="turbineChecks"
                      :loading="checksLoading"
                      :selectItem="selectItem"
                      :unselectItem="unselectItem"
                      sortBy="aep_loss_pct_open"
                      :itemsPerPage="10"
                      :userHasInternalAccess="
                        turbineData.user_has_internal_access
                      "
                      @goToIssuesTable="goToIssuesTab"
                      @getChecksTableData="callForChecks"
                    />
                  </KeepAlive>
                </template>
                <template v-if="tabItem.value === 'issues'">
                  <IssueTable
                    ref="issuesTable"
                    v-if="dashboardTabs[tab].value === 'issues'"
                    :data="paginatedIssues"
                    :totalItems="issuesTotal"
                    :loading="paginatedIssuesLoading"
                    :selectItem="selectItem"
                    :unselectItem="unselectItem"
                    @paginationUpdated="getNewItemsSet"
                  />
                </template>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col sm="12" md="12" lg="12" xl="3">
            <div class="map-switch-anchor">
              <MapView
                v-if="showMap"
                :mapData="mapData"
                :isMapLoading="!summaryDataValid"
              />
            </div>
          </v-col>
        </v-row>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import L from "leaflet";
import TurbineSummaryWidget from "@/components/TurbineSummaryWidget";
import IssueTable from "@/components/IssueTable";
import ChecksTable from "@/components/ChecksTable";
import Header from "@/components/Header";
import SwitchButton from "@/components/SwitchButton";
import MapView from "@/components/MapView";
import {
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  clickCountLimit,
} from "@/helpers/variables";
import {
  roundToString,
  roundAepPct,
  getDashboardTabs,
  setUrlParams,
} from "@/helpers/functions";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";

export default {
  name: "TurbineDashboard",
  components: {
    MapView,
    IssueTable,
    ChecksTable,
    TurbineSummaryWidget,
    Header,
    SwitchButton,
  },
  data() {
    return {
      orgId: this.$route.params.orgId,
      siteId: this.$route.params.siteId,
      turbineId: this.$route.params.turbineId,
      selected: "Month",
      mapData: {
        zoom: 15,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
        markers: [],
      },
      tableData: [],
      summaryData: {},
      aepCost: 0,
      showMap: true,
      isMapLoading: false,
      tab: 0,
      windowWidth: window.innerWidth,
      popupLoading: false,
      dashboardTabs: [],
      leftmostTab: "",
      setUrlParams,
    };
  },
  computed: {
    ...mapState({
      paginatedIssues: (state) => state.turbine.paginatedIssuesForIssuesTable,
      issuesTotal: (state) => state.turbine.paginatedIssuesTotal,
      paginatedIssuesLoading: (state) =>
        state.turbine.loading.getPaginatedIssuesForIssuesTable,
      turbineChecksData: (state) => state.turbine.checks,
      checksLoading: (state) => state.turbine.loading.getChecks,
      turbineData: (state) => state.turbine.turbineData,
      clickCount: (state) => state.app.clickCount,
      userData: (state) => state.user.userData,
      loading: (state) => state.turbine.loading.getTurbine,
    }),
    summaryDataValid() {
      if (
        this.turbineData &&
        this.turbineData.id == this.$route.params.turbineId &&
        this.turbineData.subscription
      ) {
        return true;
      } else {
        return false;
      }
    },
    turbineChecks() {
      if (
        this.turbineChecksData &&
        Object.keys(this.turbineChecksData).length > 0 &&
        this.turbineChecksData.results?.length > 0
      ) {
        return this.turbineChecksData.results;
      }
      return [];
    },
    subscription() {
      if (this.turbineData && this.turbineData.subscription) {
        return (
          "Subscription: " + this.turbineData.subscription?.definition?.name
        );
      }
      return "";
    },
  },
  methods: {
    ...mapActions({
      getPaginatedIssuesForIssuesTable:
        "turbine/getPaginatedIssuesForIssuesTable",
      getChecks: "turbine/getChecks",
      getTurbineData: "turbine/getTurbine",
      getConverterData: "turbine/getConverterData",
      incrementClickCount: "app/incrementClickCount",
    }),
    handleTabClick(tab) {
      const url = new URL(window.location.href);
      url.search = "";
      url.hash = tab.value;
      if (
        (tab.value === "checks" || tab === "checks") &&
        url.hash !== "checks"
      ) {
        url.hash = "checks";
        url.searchParams.set("items_per_page", 10);
        url.searchParams.set("page", 1);
        url.searchParams.set("sort_by", "aep_loss_pct_open");
        url.searchParams.set("sort_desc", 1);
        this.tab = 1;
      }

      history.replaceState(null, null, url);
    },
    goToTab() {
      const hashName = decodeURI(window.location.hash).split("#")[1];
      const tabIndex = this.dashboardTabs.findIndex(
        (x) => x.value === hashName,
      );
      if (tabIndex > -1) {
        this.tab = tabIndex;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    setSummaryData() {
      if (this.turbineData.issues_summary) {
        const summary = this.turbineData.issues_summary;
        let ratedPowerToMW =
          this.turbineData.turbine_model.rated_power_kw / 1000;

        this.summaryData = {
          turbineName: `Turbine ${this.turbineData.shortname}`,
          subscriptionName: `Subscription: ${this.turbineData.subscription.definition.name}`,
          turbineModel: {
            title: "Turbine model",
            manufacturer: this.turbineData.turbine_model.manufacturer.name,
            model: this.turbineData.turbine_model.name,
            yearInstalled: this.turbineData.year_installed,
            ratedPowerMW: roundToString(ratedPowerToMW, 1),
          },
          issue: {
            title: "Issues",
            requireExternalInput: summary.requires_external_input
              ? summary.requires_external_input.n
              : 0,
            open: summary.open ? summary.open.n : 0,
            fixed: summary.fixed_or_resolved ? summary.fixed_or_resolved.n : 0,
            unconfirmed: summary.unconfirmed ? summary.unconfirmed.n : 0,
            issueDefIdCount: summary.requires_external_input
              ? summary.requires_external_input.turbine_issue_def_ids.length
              : 0,
          },
          totalGains: {
            title: `AEP gain from fixed issues`,
            mwh: summary.fixed_or_resolved.aep_gain_mwhpyr
              ? roundToString(summary.fixed_or_resolved.aep_gain_mwhpyr, -1)
              : 0,
            usd: summary.fixed_or_resolved.aep_gain_upper_usdpyr
              ? roundToString(
                  summary.fixed_or_resolved.aep_gain_upper_usdpyr,
                  -1,
                )
              : 0,
            percent: summary.fixed_or_resolved.aep_gain_pct
              ? roundAepPct(summary.fixed_or_resolved.aep_gain_pct)
              : 0,
          },
          aepPotential: {
            title: "AEP potential for open issues",
            mwh: summary.open.aep_loss_mwhpyr
              ? roundToString(summary.open.aep_loss_mwhpyr, -1)
              : 0,
            usd: summary.open.aep_loss_upper_usdpyr
              ? roundToString(summary.open.aep_loss_upper_usdpyr, -1)
              : 0,
            percent: summary.open.aep_loss_pct
              ? roundAepPct(summary.open.aep_loss_pct)
              : 0,
          },
        };
      }
    },
    setMapData() {
      if (this.turbineData && Object.keys(this.turbineData).length > 0) {
        const turbineDetailsArr = [];
        turbineDetailsArr.push(this.turbineData);
        let mapConfig = [];

        mapConfig = turbineDetailsArr.map((item) => ({
          id: item.id,
          site_id: item.farm_id,
          turbine_name: item.name,
          turbine_model: item.turbine_model.name,
          issues_that_need_attention: item.issues_summary.open
            .n_require_external_input
            ? item.issues_summary.open.n_require_external_input
            : 0,
          color: item.issues_that_need_attention > 0 ? "red" : "blue",
          position: L.latLng(item.latitude_deg, item.longitude_deg),
          selected: false,
          mapInfoTitle: item.shortname,
          mapInfoDesc: `Issues: ${
            item.issues_summary.open.n_issues
              ? item.issues_summary.open.n_issues
              : 0
          }`,
        }));

        this.mapData.markers = mapConfig;
      }
    },
    changeShowMap(value) {
      this.showMap = value;
      const eventLabel = `turbine_map_toggle_${value}`;
      // Track filter selections
      if (this.clickCount < clickCountLimit) {
        // Data sent to Google Analytics
        if (eventLabel) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: eventLabel.toLowerCase(),
            value: this.clickCount,
          });
        }
      }
    },
    selectItem(item) {
      item.selected = true;
    },
    unselectItem(item) {
      item.selected = false;
    },
    getNewItemsSet(payload) {
      if (this.dashboardTabs[this.tab].value !== "issues") return;
      const turbineId = +this.$route.params.turbineId;
      this.getPaginatedIssuesForIssuesTable({
        turbineId,
        ...payload,
      });
    },
    callForChecks(type) {
      // Type defines the functionality for each call
      const turbineId = +this.$route.params.turbineId;
      if (this.checksLoading) {
        return;
      }
      if (
        ((this.turbineChecksData?.results?.length === 0 ||
          this.turbineChecksData?.turbine_id !== turbineId) &&
          (this.tab === 1 || type === "tabClicked" || type === "gotToTab")) ||
        (this.turbineChecksData?.results?.length > 0 && type === "refresh")
      ) {
        this.getChecks({ turbineId });
      }
    },
    goToIssuesTab(type, source, name) {
      for (const tab of this.dashboardTabs) {
        if (tab.value === "issues") {
          this.tab = this.dashboardTabs.indexOf(tab);
          break;
        }
      }
      // Set issue status filters
      if (type && source) {
        const url = new URL(window.location.href);
        let tempUrl = null;
        let newUrl = null;
        url.search = "";
        let params = {
          include_statuses: [],
          page: 1,
          items_per_page: 5,
          sort_by: "aep_loss_pct",
          sort_desc: 1,
        };
        if (type === "open") {
          params.include_statuses = ["new", "in_progress"];
        } else if (type === "fixed") {
          params.include_statuses = ["fixed"];
        } else if (type === "unconfirmed") {
          params.include_statuses = ["unconfirmed", "needs_review"];
        }
        if (name) {
          params.search_for = name;
        }
        // Call method inside issues table to set filters inside issues table
        if (url.hash === "#issues" && source === "summary") {
          this.$refs["issuesTable"][0].changeStatusFilters(
            params.include_statuses,
          );
        }
        tempUrl = this.setUrlParams(url, params, "include_status");
        url.hash = "issues";
        if (tempUrl) {
          newUrl = tempUrl;
        } else {
          newUrl = url;
        }
        history.replaceState(null, null, newUrl);
      } else {
        this.handleTabClick(this.tab);
      }
    },
    async forceRefresh() {
      const turbineId = +this.$route.params.turbineId;
      this.getTurbineData({
        turbineId: turbineId,
        getIssuesSummary: 1,
        getSubscription: 1,
        recursive: 1,
      });
      if (this.turbineChecksData.results?.length > 0) {
        this.callForChecks("refresh");
      }
      if (this.paginatedIssues.length > 0) {
        const payload = {
          include_statuses: ["unconfirmed", "new", "in_progress"],
          initial: true,
          items_per_page: 10,
          page: 1,
          sort_by: "aep_loss_pct",
          sort_desc: 1,
          table_type: "issues",
        };
        this.getNewItemsSet(payload);
      }
    },
  },
  async beforeMount() {
    const turbineId = +this.$route.params.turbineId;
    // Change this value to the desired leftmost tab
    this.leftmostTab = "issues";
    this.dashboardTabs = getDashboardTabs("turbines", this.leftmostTab);
    // Only call for turbine data if no data or turbine id does not match route
    if (
      Object.keys(this.turbineData)?.length === 0 ||
      this.turbineData?.id !== turbineId ||
      !this.turbineData.issues_summary ||
      !this.turbineData.subscription
    ) {
      this.getTurbineData({
        turbineId: turbineId,
        getIssuesSummary: 1,
        getSubscription: 1,
        recursive: 1,
      });
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    const url = new URL(window.location.href);
    if (url.hash) {
      this.goToTab();
    } else {
      url.hash = this.leftmostTab;
      this.goToTab();
    }
    history.replaceState(null, null, url);

    window.addEventListener(
      "hashchange",
      () => {
        this.goToTab();
      },
      false,
    );
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    summaryDataValid: {
      immediate: true,
      handler(data) {
        if (data) {
          this.setSummaryData();
        }
      },
    },
    turbineData: {
      immediate: true,
      handler(data) {
        if (
          data &&
          Object.keys(data).length > 0 &&
          data.issues_summary &&
          data.subscription
        ) {
          this.setMapData();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-wrapper {
  width: 13rem;
}

::v-deep {
  .filter-wrapper {
    .v-input__control {
      .v-input__slot {
        background-color: transparent;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        font-family: "Museo Sans Rounded" !important;
        height: 2rem;

        .v-select__selection {
          line-height: 1;
        }
      }
    }
  }
}

.loader {
  margin: auto;
}
</style>
