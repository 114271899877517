<template>
  <v-card outlined class="summary-widget h100">
    <div v-if="loading" class="d-flex align-center justify-center h100">
      <v-progress-circular
        :size="summaryWidgetSpinnerSize"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="d-flex flex-column justify-space-between h100">
      <p class="widget-title widget-header">
        {{ summaryData.aepPotential.title }}
      </p>
      <div class="d-flex align-center flex-wrap">
        <h3>
          ${{
            summaryData.aepPotential.percent == "0.0"
              ? 0
              : data.aepPotential.usd
          }}/yr
        </h3>
      </div>
      <!-- data.aepPotential.energy > -1 && data.aepPotential.percent == "0.0"
              ? 0
              : data.aepPotential.energy -->
      <p class="widget-title widget-footer flex-wrap">
        <span class="mr-2">
          {{ mwhPerYr }}
          MWh/yr
        </span>
        <span
          :class="[
            summaryData.aepPotential.percent >= 0 ? 'increase' : 'decrease',
            'total-percent',
          ]"
        >
          {{ summaryData.aepPotential.percent >= 0 ? "+" : "-"
          }}{{ summaryData.aepPotential.percent }}%
        </span>
      </p>
    </div>
  </v-card>
</template>

<script>
import { summaryWidgetSpinnerSize } from "@/helpers/variables";

export default {
  name: "AepPotentialCard",
  components: {},
  data() {
    return {
      summaryWidgetSpinnerSize,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    openIssueTurbineCount: {
      type: Number,
      required: false,
      default: null,
    },
    turbineCount: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    summaryData() {
      return this.data;
    },
    mwhPerYr() {
      if (this.data.aepPotential.percent == "0.0") {
        return 0;
      } else {
        return this.data.aepPotential.energy || this.data.aepPotential.mwh;
      }
    },
  },
};
</script>
