var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-center"},[_c('div',{staticClass:"mr-3 mb-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","clearable":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"checks_table_search","value":_vm.searchText},on:{"input":_vm.updateSearch}})],1),_c('div',{staticClass:"mb-3 column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAll,"table":"checks_table","offset":"y","filterType":"column"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)]),_c('div',[_c('DataTable',{key:_vm.searchText,attrs:{"data-cy":"checks-data-table","className":"no-background checks-table","itemKey":"id","search":_vm.searchText,"showSelect":false,"items":_vm.tableData,"table":"checks","headers":_vm.mutableTableHeaders,"itemsPerPage":_vm.itemsPerPage,"loading":_vm.dataLoading,"sortBy":_vm.sortBy,"sort-desc":true},on:{"getData":_vm.getData},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unselectItem(item)}}},[(_vm.checkColumn('name'))?_c('td',{class:{ pointer: _vm.isSiteDashboard }},[_c('span',{staticClass:"d-flex"},[(_vm.isSiteDashboard && _vm.siteId)?_c('router-link',{staticClass:"issue-link",attrs:{"data-ga":("checks_table_link_check_detail_page_" + (item.name)),"to":{
                    name: 'Check Detail',
                    params: {
                      siteId: _vm.siteId,
                      issueDefId: item.turbine_issue_def_id,
                    },
                  }}},[_vm._v(_vm._s(item.name))]):_c('div',[_vm._v(_vm._s(item.name))]),_c('PopUp',{attrs:{"content":[item.description]},scopedSlots:_vm._u([{key:"hover",fn:function(){return [_c('v-icon',{staticStyle:{"transform":"scale(0.8)"}},[_vm._v(_vm._s(_vm.informationIcon))])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"mt-2 mb-2 mr-3 ml-3"},[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(_vm._s(item.description))])],1)]},proxy:true}],null,true)})],1)]):_vm._e(),(_vm.checkColumn('n_open'))?_c('td',[_c('span',{class:[
                  item.n_open > 0
                    ? 'has-issue error--text card-status-link lighten-error-color'
                    : 'no-issue' ],attrs:{"data-ga":("checks_table_link_to_issues_table_filtered_by_open_" + (item.name.replaceAll(
                  ' ',
                  '_'
                )))},on:{"click":function($event){item.n_open
                    ? _vm.goToIssuesTable('open', 'checks', item.name)
                    : ''}}},[_vm._v(_vm._s(item.n_open ? item.n_open : 0))])]):_vm._e(),(_vm.checkColumn('n_require_external_input'))?_c('td',[_c('span',{class:[
                  item.n_require_external_input > 0
                    ? 'has-issue error--text card-status-link lighten-error-color'
                    : 'no-issue' ],attrs:{"data-ga":("checks_table_link_to_issues_table_filtered_by_open_" + (item.name.replaceAll(
                  ' ',
                  '_'
                )))},on:{"click":function($event){item.n_require_external_input
                    ? _vm.goToIssuesTable('open', 'checks', item.name)
                    : ''}}},[_vm._v(" "+_vm._s(item.n_require_external_input ? item.n_require_external_input : 0)+" ")])]):_vm._e(),(_vm.userHasInternalAccess && _vm.checkColumn('n_unconfirmed'))?_c('td',[_c('span',{class:[
                  item.n_unconfirmed > 0
                    ? 'has-issue error--text card-status-link lighten-error-color'
                    : 'no-issue' ],attrs:{"data-cy":"unconfirmed-table-cell","data-ga":("checks_table_link_to_issues_table_filtered_by_unconfirmed_" + (item.name.replaceAll(
                  ' ',
                  '_'
                )))},on:{"click":function($event){item.n_unconfirmed
                    ? _vm.goToIssuesTable('unconfirmed', 'checks', item.name)
                    : ''}}},[_vm._v(" "+_vm._s(item.n_unconfirmed ? item.n_unconfirmed : 0)+" ")])]):_vm._e(),(
                _vm.checkColumn('aep_loss_pct_open') ||
                _vm.checkColumn('aep_loss_farm_pct_open')
              )?_c('td',[(
                  item.aep_loss_pct_open !== null ||
                  item.aep_loss_farm_pct_open !== null
                )?_c('div',{staticClass:"site-name"},[_vm._v(" "+_vm._s(_vm.roundAepPct(item[_vm.aepPotentialPctKey]))+" ")]):_vm._e()]):_vm._e(),(
                _vm.checkColumn('aep_gain_pct_fixed_or_resolved') ||
                _vm.checkColumn('aep_gain_farm_pct_fixed_or_resolved')
              )?_c('td',[(item.aep_gain_pct_fixed_or_resolved !== null)?_c('div',{staticClass:"site-name"},[_vm._v(" "+_vm._s(_vm.roundAepPct(item[_vm.aepGainPctKey]))+" ")]):_vm._e()]):_vm._e(),(_vm.checkColumn('gain_upper_usd'))?_c('td',[(item.gain_upper_usd > 0)?_c('div',{staticClass:"site-name"},[_vm._v(" $"+_vm._s(item.gain_upper_usd ? _vm.roundToString(item.gain_upper_usd, -1) : null)+" ")]):_vm._e()]):_vm._e(),(
                (_vm.isSiteDashboard || _vm.isTurbineDashboard) &&
                _vm.checkColumn('last_run')
              )?_c('td',[(item.last_run !== null)?_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.dayjs(item.last_run).format("YYYY-MM-DD"))+" ")]):_vm._e()]):_vm._e(),(
                _vm.userHasInternalAccess &&
                _vm.isSiteDashboard &&
                _vm.checkColumn('latest_result')
              )?_c('td',{attrs:{"data-cy":"latest-result-cell-site"}},[_c('ul',[(
                    item.latest_result &&
                    Object.keys(item.latest_result).length > 0
                  )?_c('li',[_vm._v(" "+_vm._s(Object.keys(item.latest_result)[0])+": "+_vm._s(Object.values(item.latest_result)[0])+" ")]):_vm._e(),(
                    item.latest_result &&
                    Object.keys(item.latest_result).length > 0
                  )?_c('li',[_vm._v(" "+_vm._s(Object.keys(item.latest_result)[1])+": "+_vm._s(Object.values(item.latest_result)[1])+" ")]):_vm._e()])]):_vm._e(),(
                _vm.userHasInternalAccess &&
                _vm.isTurbineDashboard &&
                _vm.checkColumn('latest_result')
              )?_c('td',[(item.latest_result)?_c('div',[_vm._v(_vm._s(item.latest_result))]):_vm._e()]):_vm._e()])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }