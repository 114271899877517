<template>
  <v-card outlined class="summary-widget h100">
    <div v-if="loading" class="d-flex align-center justify-center h100">
      <v-progress-circular
        :size="summaryWidgetSpinnerSize"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="d-flex flex-column justify-space-between h100">
      <p class="widget-title widget-header">
        {{ data.turbineModel.title }}
      </p>
      <div class="d-flex align-center flex-wrap">
        <h3 class="mr-2">
          {{ data.turbineModel.manufacturer }}
          {{ data.turbineModel.model }}
        </h3>
        <span>{{ data.turbineModel.ratedPowerMW }} MW</span>
      </div>
      <p class="widget-title widget-footer flex-wrap">
        <span class="mr-2"> Installed </span>
        <span>
          {{ data.turbineModel.yearInstalled }}
        </span>
      </p>
    </div>
  </v-card>
</template>
<script>
import { summaryWidgetSpinnerSize } from "@/helpers/variables";

export default {
  name: "TurbineModelCard",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      summaryWidgetSpinnerSize,
    };
  },
};
</script>
