<template>
  <v-card outlined class="summary-widget issue-widget h100">
    <div v-if="loading" class="d-flex align-center justify-center h100">
      <v-progress-circular
        :size="summaryWidgetSpinnerSize"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="d-flex flex-column justify-space-between h100">
      <div class="d-flex justify-space-between">
        <p class="widget-title widget-header">
          {{ data.issue.title }}
        </p>
      </div>
      <div class="pt-2">
        <h3 class="error--text">
          <div class="description d-flex">
            <div
              class="d-flex flex-wrap"
              v-if="'issueDefIdCount' in data.issue"
            >
              <div class="d-flex mr-1">
                <div class="mr-1">
                  {{
                    data.issue.issueDefIdCount
                      ? data.issue.issueDefIdCount.toLocaleString()
                      : 0
                  }}
                </div>
                <p
                  data-ga="issue_count_card_link_checks"
                  class="ma-0 check"
                  @click="$emit('goToChecksTab')"
                >
                  {{
                    pluralize(data.issue.issueDefIdCount === 1 ? 2 : 1, "check")
                  }}
                </p>
              </div>
              <div class="d-flex flex-wrap">
                <p class="ma-0 mr-1">
                  {{ pluralize(data.issue.issueDefIdCount, "need") }}
                </p>
                <p class="ma-0">attention</p>
              </div>
            </div>
            <!-- Check detail page -->
            <div v-else>
              <div class="d-flex">
                <div class="mr-1">
                  {{
                    data.issue.attention
                      ? data.issue.attention.toLocaleString()
                      : 0
                  }}
                </div>
                <div class="mr-1">
                  {{ pluralize(data.issue.attention, "need") }}
                </div>
                <div>attention</div>
              </div>
            </div>
          </div>
        </h3>
      </div>
      <div>
        <div class="d-flex widget-title widget-footer flex-wrap">
          <div class="d-flex flex-wrap">
            <div
              class="d-flex"
              :class="{ 'card-status-link': data.issue.open > 0 }"
            >
              <p
                class="ma-0 mr-1"
                data-ga="issue_count_card_link_open"
                @click="data.issue.open > 0 ? goToIssuesTable('open') : ''"
              >
                <span data-ga="issue_count_card_link_open">{{
                  data.issue.open ? data.issue.open.toLocaleString() : 0
                }}</span>
                <span data-ga="issue_count_card_link_open">&nbsp;</span>
                <span data-ga="issue_count_card_link_open">open</span>
              </p>
            </div>
            <div v-if="openIssueTurbineCount && turbineCount">
              <p class="ma-0">
                ({{ openIssueTurbineCount }}/{{ turbineCount }} turbines
                affected)
              </p>
            </div>
          </div>
        </div>
        <div
          class="d-flex widget-title widget-footer flex-wrap confirmed-issue"
        >
          <div
            class="d-flex"
            :class="{ 'card-status-link': data.issue.fixed > 0 }"
          >
            <p
              class="ma-0 mr-1"
              data-ga="issue_count_card_link_fixed"
              @click="data.issue.fixed > 0 ? goToIssuesTable('fixed') : ''"
            >
              <span data-ga="issue_count_card_link_fixed">{{
                data.issue.fixed ? data.issue.fixed.toLocaleString() : 0
              }}</span>
              <span data-ga="issue_count_card_link_fixed">&nbsp;</span>
              <span data-ga="issue_count_card_link_fixed"
                >fixed{{ isInternal ? "," : "" }}</span
              >
            </p>
          </div>
          <div class="d-flex flex-wrap">
            <div
              class="d-flex no-wrap unconfirmed"
              :class="{ 'card-status-link': data.issue.unconfirmed > 0 }"
            >
              <div
                class="mr-1"
                data-ga="issue_count_card_link_unconfirmed"
                @click="
                  data.issue.unconfirmed > 0
                    ? goToIssuesTable('unconfirmed')
                    : ''
                "
              >
                <span data-ga="issue_count_card_link_unconfirmed">{{
                  data.issue.unconfirmed
                    ? data.issue.unconfirmed.toLocaleString()
                    : 0
                }}</span>
                <span data-ga="issue_count_card_link_unconfirmed">&nbsp;</span>
                <span data-ga="issue_count_card_link_unconfirmed"
                  >unconfirmed</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { pluralize } from "@/helpers/functions";
import { summaryWidgetSpinnerSize } from "@/helpers/variables";
import { mapState } from "vuex";

export default {
  name: "IssueCountCard",
  data() {
    return {
      pluralize,
      summaryWidgetSpinnerSize,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    openIssueTurbineCount: {
      type: Number,
      required: false,
      default: null,
    },
    turbineCount: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState({
      issuesResolved: (state) => state.issues.issuesResolved,
      isInternal: (state) => state.user.userData.is_internal,
    }),
  },
  methods: {
    goToIssuesTable(type) {
      this.$emit("goToIssuesTable", type);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-header {
  h3 {
  }
}
</style>
