<template>
  <div>
    <div class="d-flex flex-wrap justify-space-between align-center">
      <div class="mr-3 mb-3 search-filter-wrapper">
        <v-text-field
          solo
          dense
          clearable
          label="Search..."
          append-icon="mdi-magnify"
          hide-details
          class="no-border"
          data-ga="checks_table_search"
          :value="searchText"
          @input="updateSearch"
        ></v-text-field>
      </div>
      <!-- Table column filter -->
      <div class="mb-3 column-filter-wrapper">
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon id="cog-icon" class="pointer" v-on="on" v-bind="attrs"
              >mdi-cog-outline</v-icon
            >
          </template>
          <span>Column filters</span>
        </v-tooltip>
        <CustomMenuFilter
          :menuOpen="colFilterMenuOpen"
          menuActivator="#cog-icon"
          :menuItems="columnFilterMenuItems"
          :showAll="showAll"
          table="checks_table"
          offset="y"
          filterType="column"
          @changeFilters="toggleColSelection"
          @clearSelectedItems="clearSelectedColItems"
          @setSearchText="setColumnFilterSearchText"
          @clearFilterSearch="clearColumnFilterSearch"
        />
      </div>
    </div>
    <div>
      <DataTable
        data-cy="checks-data-table"
        className="no-background checks-table"
        itemKey="id"
        :search="searchText"
        :key="searchText"
        :showSelect="false"
        :items="tableData"
        table="checks"
        :headers="mutableTableHeaders"
        :itemsPerPage="itemsPerPage"
        :loading="dataLoading"
        @getData="getData"
        :sortBy="sortBy"
        :sort-desc="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              @mouseover="selectItem(item)"
              @mouseleave="unselectItem(item)"
            >
              <td
                v-if="checkColumn('name')"
                :class="{ pointer: isSiteDashboard }"
              >
                <span class="d-flex">
                  <router-link
                    v-if="isSiteDashboard && siteId"
                    class="issue-link"
                    :data-ga="`checks_table_link_check_detail_page_${item.name}`"
                    :to="{
                      name: 'Check Detail',
                      params: {
                        siteId: siteId,
                        issueDefId: item.turbine_issue_def_id,
                      },
                    }"
                    >{{ item.name }}</router-link
                  >
                  <div v-else>{{ item.name }}</div>
                  <PopUp :content="[item.description]">
                    <template v-slot:hover>
                      <v-icon style="transform: scale(0.8)">{{
                        informationIcon
                      }}</v-icon>
                    </template>
                    <template v-slot:content>
                      <div class="mt-2 mb-2 mr-3 ml-3">
                        <vue-markdown
                          :breaks="false"
                          :anchor-attributes="anchorAttrs"
                          >{{ item.description }}</vue-markdown
                        >
                      </div>
                    </template>
                  </PopUp>
                </span>
              </td>
              <td v-if="checkColumn('n_open')">
                <span
                  :data-ga="`checks_table_link_to_issues_table_filtered_by_open_${item.name.replaceAll(
                    ' ',
                    '_',
                  )}`"
                  :class="[
                    item.n_open > 0
                      ? 'has-issue error--text card-status-link lighten-error-color'
                      : 'no-issue',
                  ]"
                  @click="
                    item.n_open
                      ? goToIssuesTable('open', 'checks', item.name)
                      : ''
                  "
                  >{{ item.n_open ? item.n_open : 0 }}</span
                >
              </td>
              <td v-if="checkColumn('n_require_external_input')">
                <span
                  :data-ga="`checks_table_link_to_issues_table_filtered_by_open_${item.name.replaceAll(
                    ' ',
                    '_',
                  )}`"
                  :class="[
                    item.n_require_external_input > 0
                      ? 'has-issue error--text card-status-link lighten-error-color'
                      : 'no-issue',
                  ]"
                  @click="
                    item.n_require_external_input
                      ? goToIssuesTable('open', 'checks', item.name)
                      : ''
                  "
                >
                  {{
                    item.n_require_external_input
                      ? item.n_require_external_input
                      : 0
                  }}
                </span>
              </td>
              <td v-if="userHasInternalAccess && checkColumn('n_unconfirmed')">
                <span
                  data-cy="unconfirmed-table-cell"
                  :data-ga="`checks_table_link_to_issues_table_filtered_by_unconfirmed_${item.name.replaceAll(
                    ' ',
                    '_',
                  )}`"
                  :class="[
                    item.n_unconfirmed > 0
                      ? 'has-issue error--text card-status-link lighten-error-color'
                      : 'no-issue',
                  ]"
                  @click="
                    item.n_unconfirmed
                      ? goToIssuesTable('unconfirmed', 'checks', item.name)
                      : ''
                  "
                >
                  {{ item.n_unconfirmed ? item.n_unconfirmed : 0 }}
                </span>
              </td>
              <td
                v-if="
                  checkColumn('aep_loss_pct_open') ||
                  checkColumn('aep_loss_farm_pct_open')
                "
              >
                <div
                  v-if="
                    item.aep_loss_pct_open !== null ||
                    item.aep_loss_farm_pct_open !== null
                  "
                  class="site-name"
                >
                  {{
                      roundAepPct(item[aepPotentialPctKey]),
                  }}
                </div>
              </td>
              <td
                v-if="
                  checkColumn('aep_gain_pct_fixed_or_resolved') ||
                  checkColumn('aep_gain_farm_pct_fixed_or_resolved')
                "
              >
                <div
                  v-if="item.aep_gain_pct_fixed_or_resolved !== null"
                  class="site-name"
                >
                  {{
                      roundAepPct(item[aepGainPctKey]),
                  }}
                </div>
              </td>
              <td v-if="checkColumn('gain_upper_usd')">
                <div v-if="item.gain_upper_usd > 0" class="site-name">
                  ${{
                    item.gain_upper_usd
                      ? roundToString(item.gain_upper_usd, -1)
                      : null
                  }}
                </div>
              </td>
              <td
                v-if="
                  (isSiteDashboard || isTurbineDashboard) &&
                  checkColumn('last_run')
                "
              >
                <div v-if="item.last_run !== null" class="text-nowrap">
                  {{ dayjs(item.last_run).format("YYYY-MM-DD") }}
                </div>
              </td>
              <td
                data-cy="latest-result-cell-site"
                v-if="
                  userHasInternalAccess &&
                  isSiteDashboard &&
                  checkColumn('latest_result')
                "
              >
                <ul>
                  <li
                    v-if="
                      item.latest_result &&
                      Object.keys(item.latest_result).length > 0
                    "
                  >
                    {{ Object.keys(item.latest_result)[0] }}:
                    {{ Object.values(item.latest_result)[0] }}
                  </li>
                  <li
                    v-if="
                      item.latest_result &&
                      Object.keys(item.latest_result).length > 0
                    "
                  >
                    {{ Object.keys(item.latest_result)[1] }}:
                    {{ Object.values(item.latest_result)[1] }}
                  </li>
                </ul>
              </td>
              <td
                v-if="
                  userHasInternalAccess &&
                  isTurbineDashboard &&
                  checkColumn('latest_result')
                "
              >
                <div v-if="item.latest_result">{{ item.latest_result }}</div>
              </td>
            </tr>
          </tbody>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import DataTable from "./DataTable";
import PopUp from "@/components/PopUp";
import VueMarkdown from "vue-markdown-v2";
import { roundAepPct, roundToString } from "@/helpers/functions";
import { informationIcon, popupDelay } from "@/helpers/variables";
import dayjs from "dayjs";
import CustomMenuFilter from "./CustomMenuFilter";

/**
 * Display checks data in table format
 */
export default {
  name: "ChecksTable",
  emits: ["changeShowMap", "getData"],
  components: {
    DataTable,
    PopUp,
    VueMarkdown,
    CustomMenuFilter,
  },
  props: {
    /**
     * Checks data to be displayed; passed as props to `DataTable` component
     */
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * Loading variable passed to `DataTable` to determine loading status of the table data
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Built in function passed to `DataTable` designed to select an item in the table with an event
     */
    selectItem: {
      type: Function,
      default: () => {},
    },
    /**
     * Built in function passed to `DataTable` designed to unselect an item in the table with an event
     */
    unselectItem: {
      type: Function,
      default: () => {},
    },
    userHasInternalAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: -1,
      required: false,
    },
    page: {
      type: Number,
      default: 1,
      required: false,
    },
    sortBy: {
      type: String,
      default: "",
      required: false,
    },
    sortDesc: { Boolean, default: true, required: false },
    siteId: {
      type: Number,
      default: null,
      required: false,
    },
    isSwarm: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      /**
       * Used to hold mapped checks data for display in the checks table
       */
      tableData: [],
      /**
       * Used to hold search criteria for the checks table
       */
      searchText: "",
      /**
       * Holds the attributes to be added to the `vue-markdown` link
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      roundAepPct,
      roundToString,
      dayjs,
      informationIcon,
      popupLoading: false,
      colFilterMenuOpen: false,
      popupDelay,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      searchColumnMenuText: "",
      showAll: false,
    };
  },
  computed: {
    tableHeaders() {
      let headers = [
        {
          id: 1,
          text: "Check",
          value: "name",
          show: true,
        },
        {
          id: 2,
          text: "Open issues",
          value: "n_open",
          show: true,
        },
        {
          id: 3,
          text: "Issues that need attention",
          value: "n_require_external_input",
          show: true,
        },
        // If user is internal
        {
          id: 4,
          text: "Unconfirmed issues",
          value: "n_unconfirmed",
          show: true,
        },
        {
          id: 5,
          text: "AEP potential (%)",
          value: null,
          show: true,
        },
        {
          id: 6,
          text: "AEP gain from fixes (%)",
          value: null,
          show: true,
        },
        {
          id: 7,
          text: "Cumulative gains (USD)",
          value: "gain_upper_usd",
          show: true,
        },
        // For site dashboard or turbine dashboard
        {
          id: 8,
          text: "Last run",
          value: "last_run",
          show: true,
        },
        {
          id: 9,
          text: "Latest result",
          value: "latest_result",
          show: true,
        },
      ];
      let checksHeaders = [];
      // Choose the appropriate headers depending on table and internal access
      if (
        this.userHasInternalAccess &&
        (this.isSiteDashboard || this.isTurbineDashboard)
      ) {
        for (const item of headers) {
          if (item.show) {
            checksHeaders.push(item);
          }
        }
      } else if (
        this.userHasInternalAccess &&
        !this.isSiteDashboard &&
        !this.isTurbineDashboard
      ) {
        for (const item of headers) {
          if (item.id < 8 && item.show) {
            checksHeaders.push(item);
          }
        }
      } else if (
        !this.userHasInternalAccess &&
        (this.isSiteDashboard || this.isTurbineDashboard)
      ) {
        for (const item of headers) {
          if (item.id !== 4 && item.id !== 9 && item.show) {
            checksHeaders.push(item);
          }
        }
      } else if (
        !this.userHasInternalAccess &&
        !this.isSiteDashboard &&
        !this.isTurbineDashboard
      ) {
        for (const item of headers) {
          if (item.id !== 4 && item.id < 8 && item.show) {
            checksHeaders.push(item);
          }
        }
      }
      // Set values for aep potential and aep gains from fixed
      for (const item of checksHeaders) {
        if (item.id === 5) {
          item.value = this.aepPotentialPctKey;
        }
        if (item.id === 6) {
          item.value = this.aepGainPctKey;
        }
      }
      return checksHeaders;
    },
    isSiteDashboard() {
      return this.$route.name === "SiteDashboard";
    },
    isTurbineDashboard() {
      return this.$route.name === "TurbineDashboard";
    },
    aepPotentialPctKey() {
      return this.isTurbineDashboard
        ? "aep_loss_pct_open"
        : "aep_loss_farm_pct_open";
    },
    aepGainPctKey() {
      return this.isTurbineDashboard
        ? "aep_gain_pct_fixed_or_resolved"
        : "aep_gain_farm_pct_fixed_or_resolved";
    },
    dataLoading() {
      return this.loading;
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
  },
  methods: {
    setChecksTableData() {
      this.tableData = [];
      let checksArray = [];

      for (let i = 0; i < this.data.length; i++) {
        checksArray.push({
          id: i,
          name: this.data[i].name,
          n_open: this.data[i].n_open ? this.data[i].n_open : 0,
          n_require_external_input: this.data[i].n_require_external_input
            ? this.data[i].n_require_external_input
            : 0,
          n_unconfirmed: this.data[i].n_unconfirmed
            ? this.data[i].n_unconfirmed
            : 0,
          loss_upper_usd: this.data[i].loss_upper_usd
            ? Math.round(this.data[i].loss_upper_usd)
            : null,
          aep_loss_pct: this.data[i].aep_loss_pct
            ? roundAepPct(this.data[i].aep_loss_pct)
            : null,
          farm_name: this.data[i].farm_name,
          turbine_name: this.data[i].turbine_name,
          turbine_issue_def_id: this.data[i].turbine_issue_def_id,
          description: this.data[i].description,
          latest_result: this.data[i].latest_result,
          last_run: this.data[i].last_run,
          aep_loss_pct_open: this.data[i].aep_loss_pct_open
            ? this.data[i].aep_loss_pct_open.toFixed(1)
            : null,
          aep_gain_pct_fixed_or_resolved: this.data[i]
            .aep_gain_pct_fixed_or_resolved
            ? roundAepPct(this.data[i].aep_gain_pct_fixed_or_resolved)
            : null,
          aep_loss_farm_pct_open: this.data[i].aep_loss_farm_pct_open
            ? roundAepPct(this.data[i].aep_loss_farm_pct_open)
            : null,
          aep_gain_farm_pct_fixed_or_resolved: this.data[i]
            .aep_gain_farm_pct_fixed_or_resolved
            ? roundAepPct(this.data[i].aep_gain_farm_pct_fixed_or_resolved)
            : null,
          gain_upper_usd:
            this.data[i].gain_upper_usd > 0
              ? Math.round(this.data[i].gain_upper_usd, -1)
              : null,
          turbine_issue_ids_open: this.data[i].turbine_issue_ids_open,
          turbine_issue_ids_require_external_input:
            this.data[i].turbine_issue_ids_require_external_input,
          turbine_issue_ids_unconfirmed:
            this.data[i]?.turbine_issue_ids_unconfirmed,
        });
      }

      this.tableData = this.sortChecks(checksArray);
    },
    /**
     * Match column header with correct data value
     */
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    /**
     * Function used to emit pagination data
     */
    async getData(payload) {
      /**
       * Send pagination data to parent component `SiteDashboard`
       */
      this.$emit("getData", {
        ...payload,
      });
    },
    /**
     * Change the search term(s) for the search input
     */
    updateSearch(e) {
      const url = new URL(window.location.href);
      this.searchText = e;
      url.searchParams.set("search_for", e);
      if (!this.searchText) {
        url.searchParams.delete("search_for");
      }
      history.replaceState(null, null, url);
    },
    setSearchText() {
      const url = new URL(window.location.href);
      const search = url.searchParams.get("search_for");
      this.updateSearch(search);
    },
    goToIssuesTable(type, source, checkName) {
      this.$emit("goToIssuesTable", type, source, checkName);
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAll = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAll = false;
          break;
        }
      }
      if (this.showAll) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    sortChecks(checks) {
      let sortedArray = checks.sort((a, b) => {
        let extDiff = b.n_require_external_input - a.n_require_external_input;
        let openDiff = b.n_open - a.n_open;

        if (extDiff !== 0) {
          return extDiff;
        }

        return openDiff;
      });
      return sortedArray;
    },
  },
  mounted() {
    this.setSearchText();
    this.$emit("getChecksTableData");
  },
  activated() {
    this.setSearchText();
    this.$emit("getChecksTableData");
  },
  watch: {
    data: {
      immediate: true,
      handler(data) {
        if (data?.length > 0) {
          this.setChecksTableData();
        }
      },
    },
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";
.status {
  border-radius: 4px;
  padding: 6px 8px;
  color: $black3;
}

.search-filter {
  max-width: 18rem;
  width: 18rem;
}

.issue-filter {
  max-width: 9rem;
}

.columns-filter {
  max-width: 8rem;
}

.issue-link {
  color: var(--v-black1-base);
  text-decoration: none;
}
.issue-link:hover {
  color: var(--v-primary-base);
  text-decoration: underline;
}
</style>

<style lang="scss">
.checks-table th {
  white-space: normal;
}
</style>
