<template>
  <div>
    <v-row>
      <v-col class="summary-cards-col">
        <TurbineModelCard :data="summary" :loading="loading" />
      </v-col>
      <v-col class="summary-cards-col">
        <IssueCountCard
          :data="summary"
          :loading="loading"
          :isInternalAdmin="isInternalAdmin"
          @goToIssuesTable="goToIssuesTable"
          @goToChecksTab="goToChecksTab"
        />
      </v-col>
      <v-col class="summary-cards-col">
        <AepPotentialCard :data="summary" :loading="loading" />
      </v-col>
      <v-col class="summary-cards-col">
        <GainsFromFixedCard :data="summary" :loading="loading" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TurbineModelCard from "./SummaryCards/TurbineModelCard.vue";
import IssueCountCard from "./SummaryCards/IssueCountCard.vue";
import AepPotentialCard from "./SummaryCards/AepPotentialCard.vue";
import GainsFromFixedCard from "./SummaryCards/GainsFromFixedCard.vue";
import { pluralize } from "@/helpers/functions";

export default {
  name: "TurbineSummaryWidget",
  components: {
    TurbineModelCard,
    IssueCountCard,
    AepPotentialCard,
    GainsFromFixedCard,
  },
  data() {
    return {
      pluralize,
    };
  },
  methods: {
    goToIssuesTable(type) {
      this.$emit("goToIssuesTable", type, "summary");
    },
    goToChecksTab() {
      this.$emit("goToChecksTab");
    },
  },
  props: {
    summary: Object,
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInternalAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.summary-widget {
  .models-text {
    font-size: 1rem;
  }

  .check:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
