<template>
  <div class="d-flex justify-space-between w100">
    <div>
      <div class="d-flex flex-wrap">
        <h3 class="main-title mb-0 mr-4" data-cy="title">
          {{ returnTitle }}
        </h3>
        <div class="d-flex">
          <slot />
        </div>
      </div>
      <slot name="tabs" />
    </div>

    <div>
      <p v-if="Boolean(subscription)" class="text-no-wrap mt-2">
        <a
          @click="handleHelpClick"
          data-ga="help_link_subscription"
          data-cy="open_flyout_trigger"
        >
          <v-icon small class="mr-2 text-no-wrap info-circle">{{
            informationIcon
          }}</v-icon
          >{{ subscription }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { informationIcon } from "@/helpers/variables";
/**
 * Header display containing page title and link to subscription information
 */
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: true,
    },
    /**
     * Name of subscription
     */
    subscription: {
      type: String,
      required: false,
    },
    /**
     * Data to be displayed about subscription
     */
    subscriptionData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      informationIcon,
    };
  },
  computed: {
    returnTitle() {
      return this.title;
    },
  },
  methods: {
    ...mapActions({
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
    }),
    /**
     * Update subscription data for flyout display
     *
     * Opens the flyout containing subscription information
     */
    handleHelpClick() {
      this.updateShowBurger(true);
      this.updateBurgerData({
        isSubscription: Boolean(this.subscription),
        name: this.subscription,
        endDate: this.subscriptionData?.end_date,
        startDate: this.subscriptionData?.start_date,
        description: this.subscriptionData?.definition?.description,
      });
    },
  },
};
</script>

<style lang="scss">
.info-circle {
  color: var(--v-primary-base) !important;
}
</style>
