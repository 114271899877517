<template>
  <div class="d-flex align-center switch-button">
    <label class="mr-2 mb-0 label">{{ label }}</label>
    <v-switch
      :color="color"
      v-model="showMap"
      @change="updateChecked"
      :disabled="disabled"
      :hide-details="hideDetails"
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: "SwitchButton",
  components: {},
  data() {
    return {
      showMap: true,
    };
  },
  methods: {
    updateChecked() {
      this.$emit("changeVal", this.showMap);
    },
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
    label: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayMap: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    displayMap: {
      immediate: true,
      handler(data) {
        if (typeof data === "boolean") {
          this.showMap = data;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 0.75rem;
}
</style>
