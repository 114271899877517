<template>
  <div>
    <div class="d-flex justify-space-between flex-wrap align-center">
      <div class="d-flex">
        <div class="mr-3 mb-3 search-filter-wrapper">
          <v-text-field
            solo
            dense
            label="Search..."
            append-icon="mdi-magnify"
            hide-details
            class="no-border"
            data-ga="issue_table_search"
            :value="searchText"
            @input="updateSearch"
            @keyup.enter="handleSearch"
          >
            <template v-slot:append>
              <v-icon
                v-if="searchText"
                size="1.4rem"
                class="pointer"
                @click="clearSearch"
                >mdi-close</v-icon
              >
              <v-icon
                class="pr-2"
                :color="searchText ? 'primary' : ''"
                :class="{ pointer: searchText }"
                @click="handleSearch"
                >mdi-magnify</v-icon
              >
            </template>
          </v-text-field>
        </div>
        <div class="status-filter-wrapper">
          <v-tooltip top :open-delay="popupDelay">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                id="filter-icon"
                size="1.7rem"
                v-on="on"
                v-bind="attrs"
                class="pointer"
                >mdi-filter-variant</v-icon
              >
            </template>
            <span>Filter issue status</span>
          </v-tooltip>
          <!-- Issue status filter -->
          <CustomMenuFilter
            :menuOpen="statusFilterMenuOpen"
            menuActivator="#filter-icon"
            :menuItems="issueStatusMenuItems"
            :selectedMenuItems="selectedStatusFilters"
            :showAll="showAll"
            table="issue_table_status"
            offset="x"
            filterType="issue status"
            @changeFilters="changeStatusFilters"
            @clearSelectedItems="clearItems"
            @setSearchText="setIssueFilterSearchText"
            @clearFilterSearch="clearStatusFilterSearch"
          />
        </div>
      </div>
      <!-- Table column filter -->
      <div class="mb-3 column-filter-wrapper">
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon id="cog-icon" class="pointer" v-on="on" v-bind="attrs"
              >mdi-cog-outline</v-icon
            >
          </template>
          <span>Column filters</span>
        </v-tooltip>
        <CustomMenuFilter
          :menuOpen="colFilterMenuOpen"
          menuActivator="#cog-icon"
          :menuItems="columnFilterMenuItems"
          :showAll="showAllCols"
          offset="y"
          filterType="column"
          table="issue_table_column"
          @changeFilters="toggleColSelection"
          @clearSelectedItems="clearSelectedColItems"
          @setSearchText="setColumnFilterSearchText"
          @clearFilterSearch="clearColumnFilterSearch"
        />
      </div>
    </div>
    <div class="data-table" data-cy="issues-table-wrapper">
      <div
        class="d-flex justify-center align-center data-table__spinner"
        v-if="loading"
      >
        <v-progress-circular
          :size="45"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-data-table
        data-cy="issues-table"
        class="no-background clickable-table issues-table"
        item-key="id"
        :show-select="false"
        :headers="mutableTableHeaders"
        :items="tableData"
        :page.sync="displayPage"
        :footer-props="footerOptions"
        :items-per-page="cItemsPerPage"
        :sort-by="cSortBy"
        :options.sync="paginationOptions"
        :server-items-length="totalItems"
        @update:items-per-page="handleItemsPerPage($event)"
        @update:page="handlePage($event)"
        @update:sort-desc="handleSort($event)"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              @mouseover="selectItem(item)"
              @mouseleave="unselectItem(item)"
              class="no-pointer"
            >
              <td v-if="checkColumn('farm_name')">
                {{ item.farm_name }}
              </td>
              <td v-if="checkColumn('turbine_name')">
                {{ item.turbine_name }}
              </td>
              <td
                v-if="checkColumn('turbine_issue_def_name')"
                class="issue-column"
              >
                <span class="d-flex">
                  <div
                    class="issue-link"
                    :data-ga="`issue_table_link_issue_detail_page_${item.turbine_issue_def_name}`"
                    @click="handleClickIssue(item)"
                  >
                    {{ item.turbine_issue_def_name }}
                  </div>
                  <PopUp :content="[item.description]" class="info-icon">
                    <template v-slot:hover>
                      <v-icon style="transform: scale(0.8)">{{
                        informationIcon
                      }}</v-icon>
                    </template>
                    <template v-slot:content>
                      <vue-markdown
                        :breaks="false"
                        :anchor-attributes="anchorAttrs"
                        >{{ item.description }}</vue-markdown
                      >
                    </template>
                  </PopUp>
                </span>
              </td>
              <td v-if="checkColumn('turbine_issue_status')">
                <div
                  class="status"
                  :style="{
                    'background-color': generateStatusColor(
                      item.turbine_issue_def_status_id,
                    ),
                  }"
                >
                  {{ item.turbine_issue_status }}
                </div>
              </td>
              <td v-if="checkColumn('start_ts')">
                {{ item.start_ts }}
              </td>
              <td v-if="checkColumn('loss_upper_usd')">
                <div
                  v-if="
                    item.loss_upper_usd !== null && item.aep_loss_pct !== null
                  "
                  class="site-name"
                >
                  ${{ roundToString(item.loss_upper_usd, 0) }}
                </div>
              </td>
              <td v-if="checkColumn('aep_loss_pct')">
                <div v-if="item.aep_loss_pct !== null" class="site-name">
                  {{ roundToString(item.aep_loss_pct, 1) }}%
                </div>
              </td>
              <td v-if="checkColumn('current_status_created_ts')">
                {{ item.current_status_created_ts }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import {
  statusColors,
  generateStatusColor,
  informationIcon,
  popupDelay,
  clickCountLimit,
} from "@/helpers/variables";
import { roundToString, setUrlParams } from "@/helpers/functions";
import PopUp from "@/components/PopUp";
import VueMarkdown from "vue-markdown-v2";
import CustomMenuFilter from "./CustomMenuFilter";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";

export default {
  name: "IssueTable",
  props: {
    selectItem: {
      type: Function,
      default: () => {},
    },
    unselectItem: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
      required: false,
    },
    footerProps: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: true,
    },
    popupLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHomePage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PopUp,
    VueMarkdown,
    CustomMenuFilter,
  },
  data() {
    const paginationDefaults = {
      sort_by: "aep_loss_pct",
      page: 1,
      items_per_page: this.isHomePage ? 5 : 10,
      sort_desc: true,
    };

    return {
      colFilterMenuOpen: false,
      statusFilterMenuOpen: false,
      selectedStatusFilters: [],
      tableData: [],
      searchText: "",
      statusColors,
      generateStatusColor,
      popupDelay,
      /**
       * Holds the attributes to be added to the `vue-markdown` link
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      statusFilters: [
        {
          // Only for internal users
          text: "Unconfirmed",
          value: "unconfirmed",
        },
        // Sub category of unconfirmed so limited to internal users as well
        {
          text: "Needs review",
          value: "needs_review",
        },
        {
          text: "New",
          value: "new",
        },
        {
          text: "In progress",
          value: "in_progress",
        },
        {
          text: "Fixed",
          value: "fixed",
        },

        {
          text: "Superseded",
          value: "superseded",
        },
        {
          text: "Deferred",
          value: "deferred",
        },
        {
          text: "Dismissed",
          value: "dismissed",
        },
      ],
      searchStatusFilterText: "",
      searchColumnMenuText: "",
      roundToString,
      paginationDefaults,
      paginationOptions: {},
      displayPage: 1,
      initialData: true,
      informationIcon,
      showAll: false,
      showAllCols: false,
      staticStatusFilters: [],
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      isFirstSort: true,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
      issueStatusDefinitions: (state) => state.issues.issueStatusDefinitions,
      issuesOpen: (state) => state.issues.issuesOpen,
      isFromSwarm: (state) => state.swarm.isFromSwarm,
      clickCount: (state) => state.app.clickCount,
    }),
    tableHeaders() {
      let headers = [
        {
          id: 1,
          text: "Site",
          value: "farm_name",
          show: true,
        },
        {
          id: 2,
          text: "Turbine",
          value: "turbine_name",
          show: true,
        },
        { id: 3, text: "Issue", value: "turbine_issue_def_name", show: true },
        { id: 4, text: "Status", value: "turbine_issue_status", show: true },
        { id: 5, text: "Date found", value: "start_ts", show: true },
        {
          id: 6,
          text: "Cumulative loss (USD)",
          value: "loss_upper_usd",
          show: true,
        },
        {
          id: 7,
          text: "AEP potential (%)",
          value: "aep_loss_pct",
          show: true,
        },
        {
          id: 8,
          text: "Last updated",
          value: "current_status_created_ts",
          show: true,
        },
      ];
      let checksHeaders = [];
      // Choose the appropriate headers depending on dashboard
      if (
        this.$route.name === "HomePage" ||
        this.$route.name === "OrgDashboard"
      ) {
        for (const item of headers) {
          if (item.show) {
            checksHeaders.push(item);
          }
        }
      } else if (this.$route.name === "SiteDashboard") {
        for (const item of headers) {
          if (item.id > 1 && item.show) {
            checksHeaders.push(item);
          }
        }
      } else {
        for (const item of headers) {
          if (item.id > 2 && item.show) {
            checksHeaders.push(item);
          }
        }
      }

      return checksHeaders;
    },
    footerOptions() {
      if (this.footerProps) {
        return this.footerProps;
      } else {
        return {};
      }
    },
    isInternalUser() {
      if (this.userData && Object.keys(this.userData).length > 0) {
        return this.userData.is_internal;
      } else {
        return false;
      }
    },
    cItemsPerPage() {
      return this.paginationOptions.itemsPerPage
        ? this.paginationOptions.itemsPerPage
        : this.paginationDefaults.items_per_page;
    },
    cPage() {
      return this.paginationOptions.page || this.paginationDefaults.page;
    },
    cSortBy() {
      return this.paginationOptions.sortBy || this.paginationDefaults.sort_by;
    },
    cSortDesc() {
      return this.paginationOptions.sortDesc > -1
        ? this.paginationOptions.sortDesc
        : this.paginationDefaults.sort_desc;
    },
    issueStatusMenuItems() {
      if (this.searchStatusFilterText) {
        return this.statusFilters?.filter((status) =>
          status.value
            .toLowerCase()
            .includes(this.searchStatusFilterText?.toLowerCase()),
        );
      } else {
        return this.statusFilters;
      }
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
  },
  methods: {
    ...mapActions({
      updateIssueTurbineId: "issueDetail/updateIssueTurbineId",
      incrementClickCount: "app/incrementClickCount",
    }),
    setStatusFilterOptions() {
      if (!this.isInternalUser) {
        // If not an internal user, remove unconfirmed and needs review from the filter options
        const findUnconfirmedIndex = (element) =>
          element.value === "unconfirmed";
        const findNeedsReviewIndex = (element) =>
          element.value === "needs_review";
        const unconfirmedIndex =
          this.statusFilters.findIndex(findUnconfirmedIndex);
        const needsReviewIndex =
          this.statusFilters.findIndex(findNeedsReviewIndex);
        if (unconfirmedIndex > -1) {
          this.statusFilters.splice(this.statusFilters[unconfirmedIndex], 1);
        }
        if (needsReviewIndex > -1) {
          this.statusFilters.splice(this.statusFilters[needsReviewIndex], 1);
        }
      } else {
        this.statusFilters.push({
          text: "Dismissed (internal)",
          value: "dismissed_internal",
        });
        this.statusFilters.push({
          text: "Invalidated",
          value: "invalidated",
        });
      }
    },
    setTableData() {
      if (this.data?.length > 0) {
        this.tableData = this.data.map((r) => ({
          issues: r.issues,
          n_open: r.n_open,
          n_require_external_input: r.n_require_external_input,
          id: r.id,
          farm_name: r.farm_name,
          turbine_name: r.turbine_name,
          manufacturer_name: r.manufacturer_name,
          turbine_issue_def_name: r.name,
          turbine_issue_status: r.current_status_def_name_external,
          turbine_issue_def_status_id: r.current_status_def_id,
          start_ts: r.start_ts ? dayjs(r.start_ts).format("YYYY-MM-DD") : "N/A",
          energy_loss:
            r.loss_mwh || r.loss_mwh == 0 ? r.loss_mwh.toFixed(2) : 0,
          loss_upper_usd:
            r.loss_upper_usd !== null ? Math.round(r.loss_upper_usd) : null,
          aep_loss_pct:
            r.aep_loss_pct !== null ? r.aep_loss_pct.toFixed(1) : null,
          turbine_id: r.turbine_id ? r.turbine_id : null,
          description: r.description,
          current_status_created_ts: r.current_status_created_ts
            ? dayjs(r.current_status_created_ts).format("YYYY-MM-DD")
            : "N/A",
        }));
      } else {
        return [];
      }
    },
    handleClickIssue(issue) {
      this.updateIssueTurbineId(issue.turbine_id);
      this.$router.push({
        name: "IssueDetail",
        params: {
          orgId: issue?.org_id,
          siteId: issue?.farm_id,
          turbineId: issue?.turbine_id,
          issueId: issue.id,
        },
        query: this.$route.query,
      });
    },
    /**
     * Match column header with correct data value
     */
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    setInitialUrl() {
      this.selectedStatusFilters = [];
      this.staticStatusFilters = [];
      this.initialData = true;
      const url = new URL(window.location.href);
      let newUrl = null;
      let params = {};
      let paginationObj = {};
      this.paginationOptions = {
        itemsPerPage: this.paginationDefaults.items_per_page,
        page: 1,
        sortBy: [],
        sortDesc: [],
      };

      if (url.searchParams.toString()) {
        // Set variables to hold the url search params values
        const itemsPerPage = +url.searchParams.get("items_per_page");
        const page = +url.searchParams.get("page");
        const sortBy = url.searchParams.get("sort_by");
        const sortDesc = +url.searchParams.get("sort_desc") ? true : false;
        if (url.searchParams.get("include_status")) {
          params.include_statuses = url.searchParams.getAll("include_status");
          url.searchParams.getAll("include_status").forEach(() => {
            url.searchParams.delete("include_status");
          });
        }
        // Setting the table page options to the url params values or defaults if no url param value
        if (itemsPerPage) {
          this.paginationOptions.itemsPerPage = itemsPerPage;
        } else {
          this.paginationOptions.itemsPerPage = 10;
          params.items_per_page = 10;
        }
        if (page) {
          this.paginationOptions.page = page;
        } else {
          this.paginationOptions.page = 1;
          params.page = 1;
        }
        if (sortBy && !sortBy.includes("farm_pct_open")) {
          this.paginationOptions.sortBy.push(sortBy);
        } else {
          this.paginationOptions.sortBy.push("aep_loss_pct");
          params.sort_by = "aep_loss_pct";
        }
        if (sortDesc) {
          this.paginationOptions.sortDesc.push(sortDesc);
        } else {
          this.paginationOptions.sortDesc.push(1);
          params.sort_desc = 1;
        }
        if (params.include_statuses?.length > 0) {
          this.selectedStatusFilters = params.include_statuses;
        } else {
          if (this.isInternalUser) {
            this.selectedStatusFilters = [
              "unconfirmed",
              "needs_review",
              "new",
              "in_progress",
            ];
          } else {
            this.selectedStatusFilters = ["new", "in_progress"];
          }
          params.include_statuses = this.selectedStatusFilters;
        }
        const search = url.searchParams.get("search_for");
        if (search) {
          this.updateSearch(search);
        }
        // Setting up new url object with params
        newUrl = setUrlParams(url, params, "include_status");
      } else {
        // Setting default issue url params since there are no url params
        if (this.isInternalUser) {
          this.selectedStatusFilters = [
            "unconfirmed",
            "needs_review",
            "new",
            "in_progress",
          ];
        } else {
          this.selectedStatusFilters = ["new", "in_progress"];
        }
        params.items_per_page = this.paginationDefaults.items_per_page;
        params.page = this.paginationDefaults.page;
        params.sort_by = this.paginationDefaults.sort_by;
        params.sort_desc = this.paginationDefaults.sort_desc ? 1 : 0;
        params.include_statuses = this.selectedStatusFilters;
        // Setting up new url object with params
        newUrl = setUrlParams(url, params, "include_status");
        // Setting table pagination options
        this.paginationOptions.itemsPerPage =
          this.paginationDefaults.items_per_page;
        this.paginationOptions.page = this.paginationDefaults.page;
        this.paginationOptions.sortBy = [this.paginationDefaults.sort_by];
        this.paginationOptions.sortDesc = [
          this.paginationDefaults.sort_desc ? 1 : 0,
        ];
      }

      // Set pagination params for call to API for paginated issues
      if (this.searchText) {
        paginationObj.search_for = this.searchText;
      }
      paginationObj.include_statuses = this.selectedStatusFilters;
      // Set issue status filters
      if (this.selectedStatusFilters.length > 0) {
        for (const status of paginationObj.include_statuses) {
          this.staticStatusFilters.push(status);
        }
      } else {
        this.staticStatusFilters = [];
      }
      // Set pagination object for API call
      paginationObj.items_per_page = this.paginationOptions.itemsPerPage;
      paginationObj.page = this.paginationOptions.page;
      paginationObj.sort_by = this.setSortByName(
        this.paginationOptions.sortBy[0],
      );
      paginationObj.sort_desc = this.paginationOptions.sortDesc[0] ? 1 : 0;
      paginationObj.table_type = "issues";
      paginationObj.initial = true;

      // Send updated url to display in search window of page
      history.replaceState(null, null, newUrl);
      // Emit pagination object to site dashboard
      this.$emit("paginationUpdated", paginationObj);
    },
    paginationUpdated(value, type) {
      const url = new URL(window.location.href);
      // Gatekeepers to avoid redundant calls
      if (this.initialData && this.isFirstSort) {
        this.initialData = false;
        this.isFirstSort = false;
        return;
      } else if (this.initialData && !this.isFirstSort) {
        this.initialData = false;
        return;
      } else if (!this.initialData && this.isFirstSort) {
        this.isFirstSort = false;
        return;
      }

      const sortBy = url.searchParams.get("sort_by");

      let params = {
        page: url.searchParams.get("page") ? url.searchParams.get("page") : 1,
        items_per_page: url.searchParams.get("items_per_page")
          ? url.searchParams.get("items_per_page")
          : this.isHomePage
            ? 5
            : 10,
        sort_by:
          sortBy && !sortBy.includes("farm_pct_open")
            ? url.searchParams.get("sort_by")
            : "aep_loss_pct",
        sort_desc: url.searchParams.get("sort_desc")
          ? url.searchParams.get("sort_desc")
          : 1,
        include_statuses: url.searchParams.get("include_status")
          ? url.searchParams.getAll("include_status")
          : [],
      };
      if (url.searchParams.get("search_for")) {
        params.search_for = url.searchParams.get("search_for");
      }
      let sortDesc = 1;
      let eventLabel = "";

      switch (type) {
        case "array":
          if (value.length > 0) {
            params.include_statuses = value;
            params.page = 1;
            this.paginationOptions.page = 1;
          } else {
            params.include_statuses = [];
          }
          break;
        case "sort":
          if (value.sort_desc == 0) {
            sortDesc = 0;
          }
          params.sort_by = value.sort_by;
          params.sort_desc = sortDesc;
          if (url.searchParams.get("page") !== 1) {
            params.page = +url.searchParams.get("page");
            this.paginationOptions.page = +url.searchParams.get("page");
          } else {
            params.page = 1;
            this.paginationOptions.page = 1;
          }
          // Data sent to Google Analytics
          eventLabel = `issue_table_column_sort_${params.sort_by}_${
            params.sort_desc ? "desc" : "asc"
          }`;
          if (this.clickCount < clickCountLimit) {
            this.incrementClickCount();
            gaTrackEvent(this.$gtag, {
              eventName: "first_clicks_after_login",
              eventCategory: "user_interaction",
              eventLabel: eventLabel,
              value: this.clickCount,
            });
          }

          break;
        case "page":
          params.page = value;
          break;
        case "itemsPerPage":
          params.items_per_page = value;
          break;
      }
      if (url.searchParams.get("include_status")) {
        url.searchParams.getAll("include_status").forEach(() => {
          url.searchParams.delete("include_status");
        });
      }
      const newUrl = setUrlParams(url, params, "include_status");

      if (this.searchText) {
        params.search_for = this.searchText;
      }
      params.sort_by = this.setSortByName(params.sort_by);
      params.table_type = "issues";
      if (params.include_statuses.length > 0) {
        for (const status of params.include_statuses) {
          this.staticStatusFilters.push(status);
        }
      }
      // Send updated url to display in search window of page
      history.replaceState(null, null, newUrl);
      this.$emit("paginationUpdated", params);
    },
    handleSearch() {
      const url = new URL(window.location.href);

      if (this.searchText) {
        url.searchParams.set("page", 1);
        url.searchParams.set("search_for", this.searchText);
        if (!url.search.includes("sort_by")) {
          url.searchParams.set("sort_by", this.paginationDefaults.sortBy);
        }
        if (!url.search.includes("items_per_page")) {
          url.searchParams.set(
            "items_per_page",
            this.paginationDefaults.itemsPerPage,
          );
        }
        if (!url.search.includes("sort_desc")) {
          url.searchParams.set("sort_desc", 1);
        }
        if (!url.search.includes("include_status")) {
          let defaultStatuses = [];
          if (this.isInternalUser) {
            defaultStatuses = [
              "unconfirmed",
              "needs_review",
              "new",
              "in_progress",
            ];
          } else {
            defaultStatuses = ["new", "in_progress"];
          }
          for (const status of defaultStatuses) {
            url.searchParams.append("include_status", status);
          }
        }
        this.paginationOptions.page = 1;
        window.history.replaceState(null, null, url);

        const objToSend = {
          include_statuses: url.searchParams.get("include_status")
            ? url.searchParams.getAll("include_status")
            : [],
          sort_by: this.setSortByName(url.searchParams.get("sort_by")),
          page: 1,
          items_per_page: parseInt(url.searchParams.get("items_per_page")),
          sort_desc: parseInt(url.searchParams.get("sort_desc")),
          search_for: this.searchText,
          table_type: "issues",
        };

        this.$emit("paginationUpdated", objToSend);
      }
    },
    clearSearch() {
      const url = new URL(window.location.href);
      let statusArr = [];
      this.searchText = "";
      url.searchParams.delete("search_for", "");
      url.searchParams.set("page", 1);
      if (url.searchParams.get("include_status")) {
        for (const item of url.searchParams.getAll("include_status")) {
          statusArr.push(item);
        }
      }
      const objToSend = {
        sort_by: this.setSortByName(url.searchParams.get("sort_by")),
        page: url.searchParams.get("page"),
        items_per_page: parseInt(url.searchParams.get("items_per_page")),
        sort_desc: parseInt(url.searchParams.get("sort_desc")),
        search_for: this.searchText,
        include_statuses: statusArr,
        table_type: "issues",
      };

      window.history.replaceState(null, null, url);
      this.$emit("paginationUpdated", objToSend);
    },
    handleSort(value) {
      let objToSend = {
        sort_by: this.paginationOptions.sortBy[0],
        sort_desc: this.paginationOptions.sortDesc[0],
      };
      if (value !== undefined) {
        this.paginationUpdated(objToSend, "sort", this.isFirstSort);
      }
    },
    handlePage(value) {
      const url = new URL(window.location.href);
      const page = parseInt(url.searchParams.get("page"));

      if (value !== page) {
        url.searchParams.set("page", value);
        window.history.replaceState(null, null, url);
        this.paginationUpdated(value, "page");
      }
    },
    handleItemsPerPage(value) {
      this.paginationUpdated(value, "itemsPerPage");
    },
    setSortByName(name) {
      let sortByName = "";
      switch (name) {
        case "farm_name":
          sortByName = "site";
          break;
        case "turbine_name":
          sortByName = "turbine";
          break;
        case "turbine_issue_def_name":
          sortByName = "issue";
          break;
        case "turbine_issue_status":
          sortByName = "status";
          break;
        case "loss_upper_usd":
          sortByName = "loss_usd";
          break;
        case "aep_loss_pct":
          sortByName = "aep_loss_pct";
          break;
        default:
          sortByName = name;
          break;
      }

      return sortByName;
    },
    updateSearch(e) {
      const url = new URL(window.location.href);
      this.searchText = e;
      url.searchParams.set("search_for", e);

      history.replaceState(null, null, url);
    },
    changeStatusFilters(filter, name) {
      let areFiltersIdentical = false;

      if (filter === "selectAll") {
        this.clearStatusFilterSearch();
        if (this.selectedStatusFilters?.length !== this.statusFilters.length) {
          this.selectedStatusFilters = [];
          for (const status of this.statusFilters) {
            this.selectedStatusFilters.push(status.value);
          }
          this.showAll = true;
        } else {
          this.selectedStatusFilters = [];
          this.showAll = false;
        }
      } else if (filter === "reset") {
        this.clearStatusFilterSearch();
        if (this.isInternalUser) {
          this.selectedStatusFilters = [
            "unconfirmed",
            "needs_review",
            "new",
            "in_progress",
          ];
        } else {
          this.selectedStatusFilters = ["new", "in_progress"];
        }
      } else if (Array.isArray(filter)) {
        this.selectedStatusFilters = filter;
      } else {
        if (this.selectedStatusFilters.includes(filter.value)) {
          this.selectedStatusFilters.splice(
            this.selectedStatusFilters.indexOf(filter.value),
            1,
          );
        } else {
          this.selectedStatusFilters.push(filter.value);
        }
        if (this.selectedStatusFilters.length === this.statusFilters.length) {
          this.showAll = true;
        } else {
          this.showAll = false;
        }
      }

      if (
        this.selectedStatusFilters.length === this.staticStatusFilters.length
      ) {
        // Sorting arrays for comparison
        const sortedSelected = this.selectedStatusFilters.sort();
        const sortedStatic = this.staticStatusFilters.sort();
        areFiltersIdentical =
          JSON.stringify(sortedSelected) === JSON.stringify(sortedStatic);
      }

      if (!areFiltersIdentical) {
        this.paginationUpdated(this.selectedStatusFilters, "array");
      }

      // Resetting and updating the static filters
      this.staticStatusFilters = [];
      for (const status of this.selectedStatusFilters) {
        this.staticStatusFilters.push(status);
      }
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    clearItems() {
      this.showAll = false;
      this.selectedStatusFilters = [];
      this.staticStatusFilters = [];
      this.clearStatusFilterSearch();
      this.paginationUpdated(this.selectedStatusFilters, "array");
    },
    clearStatusFilterSearch() {
      this.searchStatusFilterText = "";
    },
    setIssueFilterSearchText(text) {
      this.searchStatusFilterText = text;
    },
  },
  beforeMount() {
    this.setStatusFilterOptions();
  },
  mounted() {
    this.setInitialUrl();
  },
  watch: {
    data: {
      handler() {
        this.setTableData();
      },
    },
    searchText: {
      handler(data) {
        if (!data) {
          this.clearSearch();
        }
      },
    },
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";
.status {
  border-radius: 4px;
  padding: 6px 8px;
  margin: 6px 0;
  text-align: center;
  display: inline-block;
  color: $black3;
}
.issue-column {
  max-width: 300px;
}
.info-icon.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-filter {
  max-width: 18rem;
  width: 18rem;
}

.status-filter-wrapper {
  padding-top: 0.4rem;
}

::v-deep {
  .no-border.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 5px;
  }
}

.columns-filter {
  max-width: 8rem;
}
.no-pointer {
  cursor: default;
}

.data-table {
  position: relative;
  min-height: 300px;

  &__spinner {
    position: absolute;
    color: var(--v-primary-base);
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: #000;
    color: var(--v-primary-base);
    opacity: 0.5;
    z-index: 100;
  }
}

.issue-link {
  color: var(--v-black1-base);
}
.issue-link:hover {
  color: var(--v-primary-base);
  text-decoration: underline;
  cursor: pointer;
}
</style>

<style lang="scss">
.issues-table th {
  white-space: normal;
}
</style>
