<template>
  <v-card outlined class="summary-widget h100 total-gain">
    <div v-if="loading" class="d-flex align-center justify-center h100">
      <v-progress-circular
        :size="summaryWidgetSpinnerSize"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="d-flex flex-column justify-space-between h100">
      <p class="widget-title widget-header">
        {{ data.totalGains.title }}
      </p>
      <div class="d-flex align-center flex-wrap">
        <h3>
          ${{ data.totalGains.percent == "0.0" ? 0 : data.totalGains.usd }}/yr
        </h3>
      </div>
      <p class="widget-title widget-footer flex-wrap">
        <span class="mr-2">
          {{ mwhPerYr }}
          MWh/yr
        </span>
        <span
          :class="[
            data.totalGains.percent >= 0 ? 'increase' : 'decrease',
            'total-percent',
          ]"
        >
          {{ data.totalGains.percent >= 0 ? "+" : "-"
          }}{{ data.totalGains.percent }}%
        </span>
      </p>
    </div>
  </v-card>
</template>

<script>
import { summaryWidgetSpinnerSize } from "@/helpers/variables";

export default {
  name: "GainsFromFixedCard",
  components: {},
  data() {
    return { summaryWidgetSpinnerSize };
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    mwhPerYr() {
      if (this.data.totalGains.percent == "0.0") {
        return 0;
      } else {
        return this.data.totalGains.energy || this.data.totalGains.mwh;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
